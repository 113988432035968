<template>
    <div class="sprint_wrapper sprint_table flex-grow flex flex-col">
        <h1 v-if="isMobile && showPageTitle && pageH1Title" class="m_page_title">
            {{ pageH1Title }}
        </h1>
        <div v-if="!isMobile" class="flex mb-2">
            <a-button 
                v-if="showCreateButton"
                @click="createDrawer = true" 
                class="mr-2" 
                icon="plus" 
                size="large" 
                type="primary">
                Создать спринт
            </a-button>
            <PageFilter 
                :model="model"
                :key="pageName"
                size="large"
                :excludeFields="excludeFields"
                :page_name="pageName"/>
            <SettingsButton
                :pageName="pageName"
                class="ml-2" />
        </div>
        <component 
            :is="sprintWidget"
            :model="model"
            :pageName="pageName"
            :tableType="tableType"
            
            :filters="filters"
            :listSprints="listSprints"
            :setSort="setSort"
            :changePage="changePage"
            :changeSize="changeSize"
            :page_size="pageSize"
            :page="page"

            :count="currentSprintCount"
            :columns="columns"
            :dataSource="listSprints"  
            :loading="listLoading"
            :pagination="false"
            :scroll="scroll"
            :getSprints="getSprints"
            :openSprint="openSprint"
            :startEdit="startEdit"
            :deleteSprint="deleteSprint"
            :updateStatus="updateStatus"/>

        <CreateDrawer
            @create="addToList"
            @update="updateList"
            :additionalData="filters"
            v-model="createDrawer"
            :edit="editSprint"
            :editData="sprintData"/>

        <div 
            v-if="isMobile" 
            class="float_add">
            <div class="filter_slot">
                <PageFilter 
                    :model="model"
                    :key="pageName"
                    size="large"
                    :excludeFields="excludeFields"
                    :page_name="pageName"/>
            </div>
            <a-button 
                v-if="showCreateButton"
                flaticon
                shape="circle"
                size="large"
                type="primary"
                icon="fi-rr-plus"
                @click="createDrawer = true"  />
        </div>
    </div>
</template>

<script>
import Pager from '../TaskList/Pager.vue'
import DateWidget from './components/DateWidget.vue'
import Actions from './components/Actions.vue'
import CreateDrawer from './CreateDrawer.vue'
import TasksCount from './components/TasksCount.vue'
import SprintStatus from './components/SprintStatus.vue'
import eventBus from '@/utils/eventBus'
import PageFilter from '@/components/PageFilter'
import mixinSort from './components/mixinSort'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import { mapState } from 'vuex'
export default {
    name: "SprintList",
    mixins: [mixinSort],
    components: { 
        Pager,
        SettingsButton,
        DateWidget,
        Actions,
        CreateDrawer ,
        TasksCount, 
        SprintStatus, 
        PageFilter 
    },
    props: {
        tableType: {
            type: String,
            default: 'sprints'
        },
        filters: {
            type: Object,
            default: null
        },
        pageName: {
            type: String,
            default: "sprint_list"
        },
        showCreateButton: {
            type: Boolean,
            default: true
        },
        model: {
            type: String,
            default: 'tasks.TaskSprintModel'
        },
        showPageTitle: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            listSprints: [],
            listTasks: [],
            listLoading: false,
            page: 1,
            pageSize: 15,
            excludeFields: [],
            editSprint: false,
            currentSprintCount: 0,
            createDrawer: false,
            sprintData: {},
            columns: [
                {
                    title: "Название",
                    dataIndex: 'name',
                    key: 'name',
            
                    scopedSlots: { customRender: 'name' },
                    width: 230,
                 
                },
               
                {
                    title: 'Цель',
                    dataIndex: 'target',
                    key: 'target',
            
                    scopedSlots: { customRender: 'target' },
                    width: 200,
                },
                {
                    title: 'Дата создания',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    width: 180,
                    scopedSlots: { customRender: 'created_at' },
                },
                {
                    title: 'Дата завершения',
                    dataIndex: 'finished_date',
                    key: 'finished_date',
                    width: 170,
                    scopedSlots: { customRender: 'finished_date' },
                },
                {
                    title: 'Задачи',
                    dataIndex: 'new_task_count',
            
                    width: 120,
                    scopedSlots: { customRender: 'task_count' },
                },
                {
                    title: 'Статус',
                    dataIndex: 'status',
            
                    width: 100,
                    scopedSlots: { customRender: 'status' },
                },
                {
                    title: this.$t('task.actions'),
                    dataIndex: 'actions',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' },
                    width: 100,
                  
                }
               
            ],
        }
    },
    computed: {
        ...mapState({
            tablesInfo: state => state.table.tablesInfo
        }),
        tableInfo() {
            return this.tablesInfo?.[this.model+this.pageName]
        },
        tableSort() {
            return this.tableInfo?.ordering || ''
        },
        tablePageSize() {
            return this.tableInfo?.page_size || null
        },
        pageH1Title() {
            return this.$route?.meta?.title ? this.$route.meta.title : null
        },
        tableScroll() {
            if(this.windowWidth > 1750)
                return false
            else
                return  1200 
        },
        scroll() {
            
            return {
                x: this.tableScroll,
                y: 'calc(100vh - 308px)'
            }
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        sprintWidget() {
            if(this.isMobile)
                return () => import('./CardList.vue')
            return () => import('./TestTable.vue')
            return () => import('./CardTable.vue')
        },
    },
    created(){
        if(!this.isMobile)
            this.getSprints()
    },
    methods: {
        async getSprints(){
            const params = { 
                page_size: this.tablePageSize,
                page: this.page,
                page_name: this.pageName,
                filters: this.filters,
                ordering: this.tableSort
            }
            try{
                this.listLoading = true
                const {data} = await this.$http(`tasks/sprint/list/`, { params })
                this.listSprints = data.results
                this.currentSprintCount = data.count
            }
            catch(e){
                console.error(e)
            }
            finally{
                this.listLoading = false
            }
        },
        addToList(data){
            this.listSprints.unshift(data)
            let query = Object.assign({}, this.$route.query)
            query['sprint'] = data.id
            this.$router.push({query})
        },
        updateList(data){
            const idx  = this.listSprints.findIndex(el=> el.id === data.id)
            this.listSprints[idx] = data
            this.editSprint = false
        },
        updateStatus(data){
            this.listSprints.find(el=> el.id === data.id).status = data.status
        },
        changePage(page) {
            this.page = page
            this.getSprints()
        },
        changeSize(size){
            this.page = 1
            this.pageSize = size
            this.getSprints()
        },
        openSprint(id){
            this.$router.push({query: {sprint: id}})
            this.$store.commit('task/CHANGE_SPRINT_SHOW', true)
        },
        deleteSprint(id){
            this.listSprints.splice(this.listSprints.findIndex(sprint => sprint.id === id), 1)
        },
        startEdit(data){
            this.sprintData = data
            this.editSprint = true
            this.createDrawer = true
        },
        setSort(sort) {
            // mixin
            // this.changeSort(sort)
        }
        
    },

    mounted() {
        eventBus.$on('sprint_update_table', sprint => {
            const idx = this.listSprints.findIndex(el=> el.id === sprint.id)
            if(idx !== -1) {
                this.$set(this.listSprints, idx, sprint)
            }
        })
        eventBus.$on(`update_filter_tasks.TaskSprintModel`, () => {
            this.page = 1
            this.getSprints()
        })
    },
    beforeDestroy(){
        eventBus.$off(`sprint_update_table`)
        eventBus.$off(`update_filter_tasks.TaskSprintModel`)
    }, 
}
</script>

<style lang="scss" >
.sprint_wrapper{
  padding: 20px 30px;

.item_name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s;
    word-break: break-word;
    cursor: pointer;
    &:hover{
        color: var(--primaryColor);
    }
    &.completed{
        color: var(--grayColor2);
        text-decoration: line-through;
    }
}
.text-tr{
   display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s;
    word-break: break-word;
}
}
</style>